import Vue from 'vue'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'

const options = {
  toast: {
    timeout: 3000, // default 2000
    showProgressBar: true, // default true
    closeOnClick: true, // default true
    pauseOnHover: true, // default true
    titleMaxLength: 150, // default 16
    bodyMaxLength: 150, // default 150
    backdrop: -1, // == disabled
    position: SnotifyPosition.rightTop,
    // buttons: // using default
    // icon: // using default
    // animation: // using default
  },
}

Vue.use(Snotify, options)

// how to deal with a message, depending on its gravity
function handleMessage(notifier, message) {
  switch (message.gravity) {
    case 1: // success
      if (message.content) {
        notifier.success(message.content, message.title)
      } else {
        notifier.success(message.title)
      }
      break
    case 0: // info
      if (message.content) {
        notifier.info(message.content, message.title)
      } else {
        notifier.info(message.title)
      }
      break
    case -1: // warning
      if (message.content) {
        notifier.warning(message.content, message.title)
      } else {
        notifier.warning(message.title)
      }
      break
    case -2: // error
      if (message.content) {
        notifier.error(message.content, message.title)
      } else {
        notifier.error(message.title)
      }
      break
  }
}

// building a notification function by using a notifier
export function notify(notifier) {
  return function (messages) {
    if (messages) {
      messages.forEach((message) => handleMessage(notifier, message))
    }
  }
}

// vue-snotify doc is here: https://artemsky.github.io/vue-snotify/documentation/api/snotify.html
//
// How to use, within a component, 2 ways :
// 1) direct use of `vue-snotify`             : this.$snotify.error("title", "message"), or this.$snotify.success("message"), etc.
// 2) automatic handling of response messages : this.notify(res.data.messages)
