import Vue from 'vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { notify } from "./plugins/vue-snotify";
import App from './views/TPMP'

Vue.config.productionTip = false

// building the root Vue instance
const vueInstance = new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
})

// adding to root instance - and thus all its children - a function to display notifications
Vue.prototype.notify = notify(vueInstance.$snotify)

// mounting this component at the right place
vueInstance.$mount('#app')
