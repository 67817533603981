import Vue from "vue";
import VueRouter from "vue-router";
import authLevel from "../components/external/modules/shared/utils/authlevels";
import path from "../shared/paths";
// import store from "../store";
import user from "../components/external/modules/user/store/constants";
import lsync from "../components/external/modules/shared/utils/lsync";

const Home = () => import("../views/TPMPHome.vue");
const Login = () => import("../views/account/Login.vue");
const Account = () => import("../views/account/Account.vue");
const Jobs = () => import("../views/admin/Jobs.vue");
// const defVue = () => import("../views/configuration/default.vue");
const TecdocIndex = () => import("../views/tecdoc/index.vue");
const TecdocSearch = () => import("../views/tecdoc/search.vue");
const TecdocArticle = () => import("../views/tecdoc/article.vue");
const TecdocOeref = () => import("../views/tecdoc/oeref.vue");
const TecdocKtyp = () => import("../views/tecdoc/ktyp.vue");
const TaskFlowConfigs = () => import("../views/configuration/FlowConfigs.vue");
const TaskFlowConfig = () => import("../views/configuration/FlowConfig.vue");
const TaskGroupConfig = () => import("../views/configuration/GroupConfig.vue");
const TaskConfig = () => import("../views/configuration/TaskConfig.vue");
const TaskFlows = () => import("../views/processing/Flows.vue");
const TaskFlow = () => import("../views/processing/Flow.vue");
const Task = () => import("../views/processing/Task.vue");

Vue.use(VueRouter);

function createRoute(path, component, authLevel, keep) {
  return {
    path: path,
    name: path,
    component: component,

    meta: {
      authLevel: authLevel,
      keep: keep
    }
  };
}

const routes = [
  { path: "*", redirect: "/home" }, // any bad route goes back to home
  createRoute(path.HOME, Home, authLevel.OPEN),
  createRoute(path.LOGIN, Login, authLevel.OPEN),
  createRoute(path.ACCOUNT, Account, authLevel.USER),
  createRoute(path.JOBS, Jobs, authLevel.ADMIN, true),
  createRoute(path.TASKFLOWCONFIGS, TaskFlowConfigs, authLevel.ADMIN, true),
  //
  createRoute(path.TASKFLOWCONFIG, TaskFlowConfig, authLevel.ADMIN, true),
  //
  createRoute(path.TASKCONFIG, TaskConfig, authLevel.ADMIN, true),
  // createRoute(path.TASKCONFIGPARAM, defVue, authLevel.ADMIN, true),
  createRoute(path.TASKGROUPCONFIG, TaskGroupConfig, authLevel.ADMIN, true),
  createRoute(path.TASKFLOWS, TaskFlows, authLevel.ADMIN, true),
  createRoute(path.TASKFLOW, TaskFlow, authLevel.ADMIN, true),
  createRoute(path.TASK, Task, authLevel.ADMIN, true),
  createRoute(path.TECDOCINDEX, TecdocIndex, authLevel.ADMIN, true),
  createRoute(path.TECDOCSEARCH, TecdocSearch, authLevel.ADMIN, true),
  createRoute(path.TECDOCARTICLE, TecdocArticle, authLevel.ADMIN, true),
  createRoute(path.TECDOCOEREF, TecdocOeref, authLevel.ADMIN, true),
  createRoute(path.TECDOCKTYP, TecdocKtyp, authLevel.ADMIN, true)
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// preventing the access to guarded routes
// using this instead of beforeEach, since we need router.app.$store to be resolved here
router.beforeResolve((to, from, next) => {
  if (to.meta.authLevel && to.meta.authLevel !== authLevel.OPEN) {
    // NB 1: check the auth level, display a message when the user has not the right level
    // NB 2: filtering out menu entries the user should never see
    if (!router.app.$store.getters[user.get.IS_LOGGED_IN](new Date())) {
      console.log("Not logged in, so back to the login page!");
      next({ path: path.LOGIN, query: { next: to.fullPath } });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

// doing stuff after each route pushing
// router.afterEach((to, from) => {
router.afterEach((to) => {
  if (to.meta.keep) {
    // if (to.meta.keep && from.path !== path.ACCOUNT) {
    const pathLastViewUid = "path_last_view_" + to.fullPath;
    lsync(pathLastViewUid, new Date().toString());
  }
});

export default router;
