export default {
  HOME: "/home",
  LOGIN: "/login",
  ACCOUNT: "/account",
  JOBS: "/jobs",
  TASKFLOWCONFIGS: "/taskflowconfigs",
  TASKFLOWCONFIG: "/taskflowconfigs/:which",
  TASKCONFIG: "/taskconfigs/:which",
  // TASKCONFIGPARAM: "/taskconfigparams/:which",
  TASKGROUPCONFIG: "/taskgroupconfigs/:which",
  TASKFLOWS: "/taskflows",
  TASKFLOW: "/taskflows/:which",
  TASK: "/tasks/:which",
  TECDOCINDEX: "/tecdoc/index",
  TECDOCSEARCH: "/tecdoc/index/:search",
  TECDOCARTICLE: "/tecdoc/article/search",
  TECDOCOEREF: "/tecdoc/oeref/search",
  TECDOCKTYP: "/tecdoc/ktyp/search",
};
